.usrl_txtfld__user {
    width: 100% !important;
}

.adurl_btn__user {
    background-color: #1e2738;
    padding: 20px;
    color: white;
}

.bnbt_stl__user {
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 14px !important;
    border-radius: 30% !important;
}

.bnbt_stl_c__user {
    color: #ecae33 !important;
}

.stp_dtstl__user {
    background-color: #ecae33 !important;
}

.stp_txt__user{
    background-color: #1e2738 !important;
    color: white;
    font-weight: bold!important;
    border: 1px solid #1e2738;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
}
