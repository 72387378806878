.container {
    color: black;
    font-family: "Poppins";
    padding-top: 80px;
  }
  .paid__orders {
    font-size: 16px;
    background-color: #1E2738 !important;
    height: 54px !important;
    border-radius: 10px !important;
    color: rgb(218, 214, 214) !important;
    margin-right: 12px !important;
    font-family: "Poppins" !important;
    font-weight: lighter !important;
    text-transform: none!important;
  }
  .paid_span__orders {
    color: white !important;
    font-weight: bold;
    font-family: "Poppins" !important;
  }
  .unpaid__orders {
    font-size: 16px;
    height: 54px !important;
    border-radius: 10px !important;
    color: #1E2738 !important;
    border-color: #1E2738 !important;
    font-family: "Poppins" !important;
    margin-right: 12px !important;
    text-transform: none!important;
  }
  .unpaid_span__orders {
    font-weight: 900 !important;
    font-family: "Poppins" !important;
  }
  .arrow_orders {
    font-size: 20px !important;
    margin-left: 90px!important;
  }
  .btn-add__orders {
    height: 48px !important;
    width: 283px !important;
    background-color: #1e2738 !important;
    font-size: 16px;
    color: white!important;
    border-radius: 7px!important;
    margin-top: 20px!important;
    font-family: 'Poppins';
    text-transform: none!important;
  }
  .Search_Feild__orders{
    margin-top: 20px!important;
    width: 770px!important;
  }
  .Menu_today__orders{
    width: 200px!important;
   
  }
  
  /* _____________________ */
  
  .add_order_btn__orders {
    font-family: "Poppins" !important;
    background-color: #1e2738 !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    height: 48px;
    text-transform: none !important;
    letter-spacing: 1px !important;
  }
  
  .viewKhata_order_btn__orders {
    font-family: "Poppins" !important;
    background-color: #ECAE33 !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    height: 48px;
    text-transform: none !important;
    letter-spacing: 1px !important;
  }
  
  .download_report_btn__orders {
    font-family: "Poppins" !important;
    background-color: white !important;
    color: #1e2738 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    height: 48px;
    text-transform: none !important;
    letter-spacing: 1px !important;
    border: 1px solid #1e2738!important;
    box-shadow: none!important;
  }
  
  #header__orderm .MuiButton-root{
    padding: 6px 10px 6px 10px!important;
  }
  
  .action_btn__orderm{
    font-size: 24px!important;
    color: #1e2738!important;
    border-radius: 50%!important;
    
    border: 1px solid #1e2738!important;
  }
  
  .action_btn_filled__orderm{
    background-color: #1e2738!important;
    color: white!important;
  }
  
  
  .order_sc__s {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  
  .order_sc__c {
    display: inline-block;
  }
  
  .order_sc__s::-webkit-scrollbar {
    display: none;
  }
  