.inv_head {
  font-size: 20px;
  color: black;
  font-weight: bold;
  text-align: center;
}
.header__ai__view_sel {
  background: rgb(255, 255, 255);
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%);

  height: 75px;
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main_back {
  background: rgb(255, 255, 255);
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%);
  height: "100%";
}
.inv_frwd {
  z-index: 10;
}
.discard_btn {
  font-family: "Poppins" !important;
  background-color: white !important;
  color: #1e2738 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  height: 35px;
  width: 100px;
  text-transform: none !important;
  letter-spacing: 1px !important;
  border: 1px solid #1e2738 !important;
  box-shadow: none !important;
}
.move_btn {
  width: 120px;
  font-family: "Poppins" !important;
  background-color: #1e2738 !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  height: 35px;
  text-transform: none !important;
  letter-spacing: 1px !important;
}
.move_all_btn {
  width: 120px;
  font-family: "Poppins" !important;
  background-color: #ecae33 !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  height: 35px;
  text-transform: none !important;
  letter-spacing: 1px !important;
}
.no_prod_txt {
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  color: #940505;
}

.stepper_btn__vslin {
  background-color: blue!important;
  border-radius: 50%!important;
  width: 0px !important;
  height: 10px!important;
  color: white!important;
  padding: 0!important;
}

.customSmallTextField .MuiOutlinedInput-input{
  padding: 0px!important;  
}