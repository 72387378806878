/* Add Inventory CSS-24-12-2022 Start */

.textField__AddInventory {
  background-color: white;
  width: 100%;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 20px !important;
}

.textField__AddInventory_right_radius {
  background-color: white;
  width: 100% !important;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px 0px 0px 10px !important;
}

.box_border__addorder {
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 10px !important;
  background-color: white;
  position: relative;
}

.country_code_label_text__addorder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100%;
}
.country_code_text1__addorder {
  font-size: 14px;
  font-weight: 100 !important;
}

.country_code_label__addorder {
  position: absolute;
  left: 0;
  top: 18px;
  z-index: 9999;
}

.select_code_text__addorder {
  font-weight: 100 !important;
  color: rgb(0, 0, 0, 0.4) !important;
  font-size: 14px;
  width: 100px !important;
}

.textField__AddInventory_left_radius {
  background-color: white;
  width: 100%;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 0px 10px 10px 0px !important;
}

.textField_variant__AddInventory {
  background-color: #f3f5f7;
  width: 100% !important;
  border: none !important;
  border-radius: 10px !important;
}

.header__ai {
  background: rgb(255, 255, 255);
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%);

  height: 60px;
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header_text__ai {
  text-align: center;
  font-size: 16px;
  color: #1e2738;
}

.header_back_btn__ai {
  position: absolute;
  left: 10px;
  font-size: 14px;
  color: #ecae33;
  cursor: pointer;
}

.card__ai {
  background: rgb(255, 255, 255);
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%);
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.05);
  font-family: "poppins" !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(0, 0, 0, 0.7);
}

.div__scroll {
  overflow: auto;
}

.div__scroll::-webkit-scrollbar {
  display: none;
}

.footer_dis__ai {
  background-color: #f3f5f7 !important;
  color: #1e2738 !important;
  font-size: 16px !important;
  padding: 10px 26px 10px 26px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.footer_add__ai {
  background-color: #ecae33 !important;
  color: white !important;
  font-size: 16px !important;
  padding: 10px 26px 10px 26px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.variant_desc__ai {
  font-size: 16px !important;
  max-width: 160px !important;
  width: 160px !important;
  text-overflow: ellipsis;
}

.variant_cell_head__ai {
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgb(0, 0, 0, 0.6) !important;
  width: 200px !important;
  max-width: 200px;
}

.variant_cell__ai {
  font-size: 16px !important;
  max-width: 100px !important;
  text-overflow: ellipsis;
}

#variant__ai {
  position: relative;
  margin-bottom: 14px !important;
}

#variant__ai .MuiTable-root {
  border-collapse: separate !important;
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
}

#variant__ai .MuiTableCell-root {
  vertical-align: sub !important;
}

.variant_container__ai {
  display: flex;
  align-items: center !important;
}

.variant_edit_btn_con__ai {
  margin-bottom: -16px;
}

.variant_edit_btn__ai {
  background-color: #ecae33 !important;
  padding: 10px !important;
}

.variant_title__ai {
  margin-top: 20px;
  margin-bottom: 20px !important;
}

.add_varient_btn__ai {
  background-color: rgb(30, 39, 56, 0.8) !important;
  border-radius: 10px !important;
  text-transform: none !important;
  font-size: 14px !important;
  margin-top: 12px !important;
}

#addVariantDialogContent::-webkit-scrollbar {
  width: 0px !important;
}

#addVariantDialogContent::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 0px !important;
}

#addVariantDialogContent::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.MuiDialog-paper {
  border-radius: 10px !important;
}

.addVariantBtn__ai {
  background-color: #1e2738 !important;
  color: white !important;
  border-radius: 10px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-bottom: 15px !important;
  margin-top: 25px !important;
}

.editVariantBtn__ai {
  background-color: transparent !important;
  color: #1e2738 !important;
  border: #1e2738 1px solid !important;
  border-radius: 10px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-bottom: 15px !important;
  margin-top: 25px !important;
  margin-right: 10px !important;
}

/* _______________CSS-24-12-2022____________________ */

.variant_imei__ai {
  font-size: 16px !important;
  max-width: 180px !important;
  width: 180px !important;
  text-overflow: ellipsis;
}

#chip__chip::-webkit-scrollbar {
  display: none;
}

#chip__chip {
  overflow-x: scroll;
  white-space: nowrap;
}

.container__addinv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.header__addinv {
  flex-shrink: 0;
}

.body__addinv {
  flex-grow: 1;
  overflow-y: scroll !important;
  overflow-x: hidden;
  background: rgb(255, 255, 255);
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%);
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.05);
  z-index: 1;
  font-family: "poppins" !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(0, 0, 0, 0.7);
}

.body__addinv::-webkit-scrollbar {
  display: none;
}

.footer__addinv {
  flex-shrink: 0;
}

.variant_cont__addinv {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.variant_body__addinv {
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: scroll !important;
}

.variant_body__addinv::-webkit-scrollbar {
  display: none;
}

/* Add Inventory CSS-24-12-2022 End */
