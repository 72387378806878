.Search_Feild__orders {
  margin-top: 20px !important;
  width: 770px !important;
}
.btn-add__orders {
  height: 48px !important;
  width: 283px !important;
  background-color: #1e2738 !important;
  font-size: 16px;
  color: white !important;
  border-radius: 7px !important;
  margin-top: 20px !important;
  font-family: "Poppins";
  text-transform: none !important;
}
.box_img__vendor_stf {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 2px !important;
}
.staff_img_sty {
  width: 50px !important;
  height: 50px !important;
  border-radius: 10px !important;
}
.div__scroll {
  overflow: auto;
}
.Box_background__vendor_stf {
  background-color: #e4f3f1 !important;
  padding: 10px !important;
}
.product_img_btn_staff {
  width: 100% !important;
  height: 54.5px !important;
  background-color: white !important;
  border-color: rgb(0, 0, 0, 0.25) !important;
  color: rgb(0, 0, 0, 0.35) !important;
  text-transform: none !important;
  font-size: 16px !important;
  justify-content: flex-start !important;
  border-radius: 10px !important;
}
.div__scroll::-webkit-scrollbar {
  display: none;
}
.balanc_icon_marg {
  margin-top: 3px !important;
}
