.broadCst_text_i {
  font-size: 23px;
  color: black !important;
  font-weight: normal;
}
.broadcst__main_one_b {
  background-color: #f4f9ff !important;
  border-radius: 10px;
  margin-top: 15px;
  padding-top: 7px;
  padding-bottom: 16px;
}

.box__broadCast_p {
  padding-left: 20px;
  padding-right: 20px;
}

.broadCast__main_second_b {
  background-color: white !important;
  width: 77%;
  border-radius: 25px;
  margin-top: 10px !important;
}
.broadCast__filt {
  font-size: 18px;
  color: #1e2738;
}
.broadCast__back {
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%) !important;
}

.add_broad_btn {
  height: 48px;
  font-family: "Poppins" !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100%;
  background-color: #1e2738 !important;
  color: white !important;
  text-transform: none !important;
  letter-spacing: 1px !important;
}
.add_broad_btn:hover {
  background-color: #35435a !important;
}

.custome_select_input__whatsapp {
  width: 100% !important;
  border-radius: 10px !important;
}
