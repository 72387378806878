.form__login {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  color: #1e2738;
}

.login_form_mobile__login {
  width: 100%;
}

.login_form_laptop__login {
  width: 505px !important;
  border: solid 1px #d3d3d3;
  border-radius: 20px;
  box-shadow: 2px 0px 22px -3px rgba(212, 189, 189, 0.64);
  -webkit-box-shadow: 2px 0px 22px -3px rgba(212, 189, 189, 0.64);
  -moz-box-shadow: 2px 0px 22px -3px rgba(212, 189, 189, 0.64);
}

.logo_laptop__login {
  background-image: url("../../../public/images/logo.png");
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: 120px 120px;
}

.logo_mobile__login {
  background-image: url("../../../public/images/logo.png");
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100px 100px;
}

.side_image__login {
  background-image: url("../../../public/images/side_image__login.png");
  background-repeat: no-repeat;
  background-size: 500px 300px;
  width: 500px;
  height: 300px;
}

.mobile_bg_container__login {
  position: absolute;
  width: 100vw;
  height: 50vh;
  opacity: 0.2;
}

.mobile_bg_container__login {
  position: absolute;
  background-image: url("../../../public/images/side_image__login.png");
  background-repeat: no-repeat;
  background-size: 100vw;
  top: 50%;
}

.wel__login {
  font-weight: 500;
  font-size: 25px;
  color: #1e2738;
  font-family: "Poppins" !important;
}
.sign__login {
  color: #1e2738 !important;
  font-size: 31px;
  font-weight: 600;
  font-family: "poppins" !important;
  letter-spacing: 1px;
}
.parkour__login {
  color: #1e2738;
  font-size: 16px !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}

.rem_item__login {
  display: flex;
  justify-content: start;
}
.button__login {
  /* width: 423px; */
  background-color: #1e2738 !important;
  height: 57px;
  margin-top: 30px;
}

.for_item__login {
  display: flex;
  justify-content: end;
  color: gray;
}

.passforget_alert__login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -80px;
  padding-top: 10px;
}

.passforget_msg__login {
  background-color: #d4edda;
  border-radius: 1px solid #84ed9d;
  border-radius: 20px;
  width: 80vw;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e2738;
  font-size: 18px;
  position: relative;
}

.close_msg__login {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(198, 20, 20);
}

.lg_aft_frgpass__login {
  font-size: 18px;
  text-decoration: underline;
}
