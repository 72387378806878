.container__inven_view {
    color: black;
    font-family: "Poppins";
    font-family: "Poppins" !important;
  }
  
  .header__inven_view {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 250, 255, 1) 38%
    );
  
    height: 60px;
    border-radius: 8px;
    border: 1px solid rgb(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .header_back_btn__inven_view {
    position: absolute;
    left: 10px;
    font-size: 14px;
    color: #ecae33;
    cursor: pointer;
  }
  
  .header_text__inven_view {
    text-align: center;
    font-size: 16px;
    color: #1e2738;
  }
  
  .page__inven_view {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .product_title_text__inven_view {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 100 !important;
    margin-bottom: 5px;
    letter-spacing: 1px !important;
  }
  
  .product_text__inven_view {
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
  }
  
  .variant_box_con__inven_view {
    background: linear-gradient(
      290.97deg,
      rgba(212, 231, 254, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border: 0.25px solid rgba(30, 39, 56, 0.2);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }
  
  .variant_title_text__inven_view {
    font-size: 15px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 100 !important;
    margin-bottom: 5px;
    letter-spacing: 1px !important;
  }
  
  .variant_text__inven_view {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
  }
  
  .container__viewinv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  
  .header__viewinv {
    flex-shrink: 0;
  }
  
  .card__inven_view {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 250, 255, 1) 38%
    );
    border-radius: 8px;
    border: 1px solid rgb(0, 0, 0, 0.05);
    z-index: 1;
    font-family: "poppins" !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: rgb(0, 0, 0, 0.7);
  }
  
  .body__viewinv {
    flex-grow: 1;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: 80vh;
  }
  
  .body__viewinv::-webkit-scrollbar {
    display: none;
  }
  
  .footer__viewinv {
    flex-shrink: 0;
  }
  