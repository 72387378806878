.services__txt{
    color: #1e2738;
    font-size: 24px;
    margin-top: 15px;
    font-family: "Poppins";
    font-weight: 500;
    padding-top: 10px;
}
.services__main_c {
    width: 330px !important;
    min-height: 180px !important;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
}
.textField__services{
    background-color: white;
    width: 100%;
    border-color: rgb(0, 0, 0, 0.25) !important;
    border-radius:20px !important;  
}
.service__space{
    margin: 5px!important;
}
.mobile_service_amont_ser{
    font-size: 24px!important;
    opacity: 0.8!important;
}
.total_profit_icon{
    height: 50px!important;
    width: 50px!important;
}