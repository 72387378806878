.container__dsh {
  padding-bottom: 10px;
  padding-right: 10px;
  color: #1e2738;
}

/* First Box Row */

.fsone_con__dsh {
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
}

.fsone_br_con__dsh {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.fsone_box_con__dsh {
  display: flex;
  align-items: center;
}
.total_numb_check_box {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-align: start;
  padding-top: 10px;
}

.fsone_box__dsh {
  background-color: rgb(235, 233, 255);
  width: 300px;
  height: 180px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}
.order_recipt_main_box {
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
}
.dashbo_pading_global {
  padding: 5px !important;
}
.dash_padin_box_one {
  padding-top: 21px !important;
  padding-left: 10px !important;
}
.dash_padin_box_two {
  padding: 5px !important;
  padding-right: 10px !important;
}
.dashbord_navi_satff_box_main {
  padding: 10px !important;
  padding-bottom: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
}
.member_dash_box {
  display: flex !important;
  align-items: center !important;
  padding-top: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.all_staff_box {
  padding: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
}
.fsdbone_box__dsh {
  background-color: rgb(237, 235, 254, 0.7);
  width: 300px;
  height: 85px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
  display: flex;
  align-items: center;
  margin-bottom: 8px !important;
}
.fsdbone_box__dsh_image {
  width: 55px !important;
  height: 55px !important;
  border-radius: 50% !important;
}
.dashb_recipt_value {
  margin-top: -1% !important;
  margin-left: 5px;
  font-size: 25px;
  color: #1e2738 !important;
}
.totalInventory_catgry_box {
  padding: 8px !important;
  display: flex;
  align-items: center !important;
}
.dash_text_fit_box_invntry {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8) !important;
}
.dashb_recipt_v_text {
  margin-top: 5px !important;
  margin-left: 10px;
  font-size: 8px;
  color: black !important;
}
.dash_global_font {
  font-size: 14px !important;
}
.fsdbone_box_emty__dsh {
  width: 300px;
  height: 85px;
  padding: 1px;
}
.member_dash_box_text {
  font-size: 20px !important;
  margin-right: 5px !important;
}
.staf_mem_box_pading {
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.member_hr_sty {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/* Second Box Row */

.scone_con__dsh {
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
}

.scone_br_con__dsh {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.scone_box_con__dsh {
  display: flex;
  align-items: center;
}

.scone_box__dsh {
  background-color: #e2fff3;
  width: 300px;
  height: 120px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}

.scdbone_box__dsh {
  background-color: #eff8f7;
  width: 300px;
  height: 55px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
}
.scdbone_box__dsh_pading{
  width: 300px;
  height: 55px;
  padding-top: 10px!important;
}

.scdbone_box_emty__dsh {
  width: 300px;
  height: 55px;
  padding: 1px;
}

/* Third Box Row */

.throne_con__dsh {
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
}

.throne_br_con__dsh {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.throne_box_con__dsh {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.throne_box1__dsh {
  background-color: #ffe1cb;
  width: 300px;
  height: 120px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
}

.throne_box2__dsh {
  background-color: #cbe9ff;
  width: 300px;
  height: 120px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
}

.thrdbone_box__dsh {
  background-color: rgb(255, 225, 203, 0.5);
  width: 300px;
  height: 56px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.thrstf_box__dsh {
  background-color: #cbe9ff;
  width: 360px;
  height: 247px;
  border-radius: 10px;
  border: 0.5px solid rgba(30, 39, 56, 0.03);
  margin-left: 10px;
  cursor: pointer;
}

/* Scroll Bar */

#scroll__dsh .TableScrollbar::-webkit-scrollbar {
  width: 0;
}

.lngtxt__dsh {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  margin-top: -10px !important;
}
.lngtxt__dsh_span {
  font-size: 14px;
  color: #1e2738 !important;
}

.brlngtxt__dsh {
  width: 120px;
  word-wrap: break-word;
}
