#table_scrollbar__inventory .TableScrollbar::-webkit-scrollbar {
  width: 0;
}

.header__custom_table {
  font-size: 15px;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
}

.fontColorLight_header__custom_table {
  color: rgb(30, 39, 56, 0.8) !important;
}

.fontColorDark_header__custom_table {
  color: rgb(30, 39, 56, 1) !important;
}

#body_row__custom_table {
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(246, 250, 255, 1) 38%);
  box-shadow: 0px 0px 30px -20px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.mark_red__custom_table {
  background: linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgb(245, 226, 226) 38%) !important;
}

.cell__custom_table {
  display: inline-block;
  font-family: "Poppins" !important;
  font-size: 15px !important;
  border-top: 1.5px solid rgba(0, 0, 0, 0.05) !important;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05) !important;
}

.row_space__custom_table {
  display: block !important;
  margin: 4px 0 !important;
}

.header_image__custom_table {
  width: 20px;
}

.container__custb {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.header_custb {
  flex-shrink: 0;
}
.body__custb {
  flex-grow: 1;
  overflow: auto;
  min-height: 2em;
}

.body__custb::-webkit-scrollbar {
  width: 0px !important;
}

.body__custb::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 0px !important;
}

.body__custb::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.footer__custb {
  flex-shrink: 0;
  margin-bottom: 20px;
  margin-top: 30px;
}

#table_scrollbar__inventory .MuiTableCell-root {
  padding: 5px 16px 0.5px 16px !important;
}

.ord_desc__orders {
  margin-top: -20px;
  margin-bottom: 5px;
  width: 200px;
  overflow-wrap: break-word !important;
  background-color: #1e2738;
  color: white;
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
}

.ord_st_desc__orders {
  background-color: red;
  border: none !important;
  color: rgb(30, 39, 56, 1) !important;
  border-radius: 10px !important;
  padding: 5px 0px 5px 0px !important;
  text-transform: none !important;
}

.textField_status__bank {
  background-color: transparent !important;
  width: 100%;
  height: 40px !important;
  border: none !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
}

.textField_status__bank .MuiSelect-select {
  padding-top: 8px !important;
}

.bnk_sts_icon__cstbl {
  color: rgb(30, 39, 56, 0.8) !important;
  padding-bottom: 8px;
  padding-right: 5px;
  padding-left: 0;
  cursor: pointer !important;
}

.serv_des__serv {
  width: 150px;
  word-wrap: break-word;
}

.pass_eye__custtbl {
  display: flex;
  justify-content: center;
  align-items: center;
}
