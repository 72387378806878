.box_img__vendor {
    height: 80px !important;
    width: 80px !important;
    border-radius: 20px !important;
    background-color: white !important;
    
  } 
  .Box_background__vendor_ac{
    border-radius: 20px!important;
    background-color: #EDEBFE;
   
  }
  .Balance_vendors__khata{
    font-size: 20px;
    font-family: 'Poppins'; 
    color: #1A2937;
  }
  .AED_vendors__khata{
    font-size: 26px;
    font-family: 'Poppins'; 
    font-weight: bold;
  }
  .header__ai__vendor_khata {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 250, 255, 1) 38%
    ); 
  
    height: 75px;
    border-radius: 8px;
    border: 1px solid rgb(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
.boxes__vendor___khata{ 
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.add_Button_back{
background-color: white;
height: 45px;
width: 45px;
border-radius: 22.5px;
display: flex;
justify-content: center;
align-items: center;
}

.add_Button_back_new{
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 22.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  }

.container_add_btn
{align-items: center;
  justify-content: center;
  z-index: 1;}

  .trasns_form_heading{
    align-items: center;
    justify-content: center;
    display: flex;
 }
.head_text{  align-self: center;
  color:'#1E2738';
  font-weight: bold;
font-size: large;}
.title__addTransaction
    {
    color:'#1E2738';
    font-size: small;
   font-weight: bold;}
.textField__addTransaction {
  background-color: white;
  width: 80%;
  font-size: medium;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-top: '-1px';

}
.card__ai {

  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 250, 255, 1) 38%
  );
}
.box_fw__expense{
  justify-content: start!important; 
}
.bnkmnu_btn__bank_ex {
  background-color: white!important;
  color: rgb(0, 0, 0, 0.6) !important;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  box-shadow: none !important;
  width: 250px !important;
  border-width:10px;
  text-align: start !important;
  height: 51px !important;
  text-transform: none !important;
}
.lngtxt_bnkslec__bank_ex {
  width: 150px;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  border-width: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}