html::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: "Poppins";
  overflow-y: scroll;
  overflow-x: hidden;
}
