.box_img__vendor_stf {
    height: 55px !important;
    width: 50px !important;
    border-radius: 15px !important;
    background-color: white !important;
 
    
  } 
  .Box_background__vendor_stf{
    border-radius: 20px!important;
    background-color: #EDEBFE;
   
  }

  .Box_background__vendor_cred_stf{
    background-color: #FFE8DE!important;
  }


  .Balance_vendors__khata_stf{
    font-size: 15px;
    font-family: 'Poppins'; 
    color: #1A2937;
  }
  .AED_vendors__khata_stf{
    font-size: 20px;
    font-family: 'Poppins'; 
    font-weight: bold;
  }
  .header__ai__vendor_khata_stf {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 250, 255, 1) 38%
    ); 
    height: 75px;
    border-radius: 8px;
    border: 1px solid rgb(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
.boxes__vendor___khata_stf{
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.add_Button_back_stf{
background-color: white;
height: 35px;
width: 35px;
border-radius: 22.5px;
display: flex;
justify-content: center;
align-items: center;
}

.container_add_btn_stf
{align-items: center;
  justify-content: center;
  z-index: 1;
}

.trasns_form_heading_stf{
    align-items: center;
    justify-content: center;
    display: flex;
 }
.head_text_stf{  align-self: center;
  color:'#1E2738';
  font-weight: bold;
font-size: large;
   }
.title__addTransaction_stf
    {
    color:'#1E2738';
    font-size: small;
   font-weight: bold;
  }
.textField__addTransaction_stf {
  background-color: white;
  width: 80%;
  font-size: medium;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-top: '-1px';

}
.card__ai_stf {

  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 250, 255, 1) 38%
  );
}
