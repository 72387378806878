.bnkmnu_btn__bank {
    background-color: #ddebff !important;
    color: rgb(0, 0, 0, 0.6) !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    box-shadow: none !important;
    width: 120px !important;
    text-align: start !important;
    height: 51px !important;
    text-transform: none !important;
}

.lngtxt_bnkslec__bank {
    width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bnkcrd_bx__bank {
    height: 50px;
    border-radius: 10px;
    border: 0.5px solid rgba(30, 39, 56, 0.03);
    display: flex;
    align-items: center;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
}

.bnkcrd_bx_icn_bx__bank {
    background-color: white;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.cio_btn__bank {
    background-color: white !important;
    padding: 5px !important;
}

.bnkcrd_txt1__bank {
    font-size: 12px;
    color: rgb(0, 0, 0, 0.6);
    padding-left: 5px;
    padding-right: 5px;
}

.bnkcrd_txt2__bank {
    padding-left: 5px;
    padding-right: 5px;
}

.bnkmnu_field__bank {
    background-color: white !important;
    color: #1a2937 !important;
    border-radius: 10px !important;
    font-size: 16px !important;
    box-shadow: none !important;
    width: 100% !important;
    text-align: start !important;
    height: 56px !important;
    text-transform: none !important;
    border: 1px solid rgb(0, 0, 0, 0.2) !important;
    margin-bottom: 14px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.chds_crd_scc__cstbl {
    color: #02833d !important;
    background-color: rgb(66, 255, 0, 0.2) !important;
    width: 60px;
    text-align: center;
    padding: 6px;
    border-radius: 20px;
}

.chds_crd_wrn__cstbl {
    color: #d88f00 !important;
    background-color: rgb(236, 174, 51, 0.2) !important;
    width: 60px;
    text-align: center;
    padding: 6px;
    border-radius: 20px;
}

.chds_crd_err__cstbl {
    color: rgb(255, 5, 5) !important;
    background-color: rgb(255, 5, 5, 0.15) !important;
    width: 60px;
    text-align: center;
    padding: 6px;
    border-radius: 20px;
}
.add_product_btn__adord_bk {
    background-color: rgb(30, 39, 56, 0.3) !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-size: 14px !important;
    margin-top: 5px !important;
    color: rgb(30, 39, 56, 1) !important;
    font-weight: 600 !important;
  }
