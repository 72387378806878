.title__accounts {
  color: #1e2738;
  font-size: 20px;
  font-weight: 500 !important;
}
.profit_card_postin{
  position: absolute!important;
  right: 50px!important;
  z-index: 99999!important;
}
.card__account {
  width: 100% !important;
  border-radius: 20px !important;
  margin-top: 10px;
  border: none !important;
}

.box_img__account {
  border-radius: 28px !important;
  background-color: white !important;
  align-items: center;
  justify-content: center;
  display: flex;
}
.total_prof_text{
  font-size: 13px!important;
  font-weight: bold!important;
  opacity: 0.7!important;
}
.total_proft_value{
  font-size: 16px!important;
  font-weight: bold!important;
}
.card_size_2__accounts {
  height: 70px !important;
  width: 70px !important;
  border-radius: 16px !important;
}

.card_size_1__accounts {
  height: 50px !important;
  width: 50px !important;
  border-radius: 16px !important;
}

.cardData_con__accounts {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.month__Account {
  font-size: 16px !important;
  font-family: "Poppins" !important;
  text-decoration: underline !important;
}
.Drop_down__account {
  font-size: 16px !important;
  opacity: 0.8 !important;
}

.total__Account {
  font-size: 20px !important;
  font-family: "Poppins" !important;
  color: gray !important;
  letter-spacing: 1px !important;
  font-weight: lighter;
}
.AED__Account {
  color: rgb(26, 41, 55, 0.9) !important;
  font-size: 26px !important;
  font-family: "Poppins" !important;
  font-weight: 600;
}
.arrow__Account {
  height: 16px !important;
  width: 16px !important;
  background-size: 16px 16px;
}

.accrd__acct {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.date_btn__accounts {
  background-color: transparent !important;
  color: black !important;
  padding: 0 !important;
  margin: 0 !important;
}

.cio_btn__accounts {
  background-color: white !important;
  padding: 10px !important;
}

.prtprft_crd__acnts {
  background: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
}
.prtnr_prf__acnts {
  color: rgb(26, 41, 55, 1);
  font-size: 14px;
  background-color: #e4f3f1;
  margin: 5px 0px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  z-index: 999999 !important;
}

.acts_prtprf__acots {
  font-size: 26px !important;
  font-weight: bold !important;
  border: none !important;
  color: rgb(30, 39, 56, 1) !important;
  border-radius: 10px !important;
  padding: 0 !important;
  text-transform: none !important;
}

.date_filter__accounts {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
}

.acc_crd_am__accounts {
  display: flex !important;
  justify-content: space-between !important;
  align-items: end !important;
}

.acc_crd_am1__accounts {
  display: flex;
  width: 100%;
}

.txt1_1_acc_crd__accounts {
  color: #1e2738;
  font-size: 18px;
}

.txt1_2_acc_crd__accounts {
  color: #1e2738;
  font-size: 14px;
}

.txt1_3_acc_crd__accounts {
  color: #1e2738;
  font-size: 12px;
}

.txt1_4_acc_crd__accounts {
  color: #1e2738;
  font-size: 10px;
}

.txt2_1_acc_crd__accounts {
  color: rgb(30, 39, 56, 0.9);
  font-size: 20px;
  font-weight: bold;
}

.txt2_2_acc_crd__accounts {
  color: rgb(30, 39, 56, 0.9);
  font-weight: bold;
}

.ac_crds_ttl__accounts {
  font-size: 20px;
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.txt1_1_acc_crd__accounts {
  color: #1e2738;
  font-size: 18px;
}

.txt1_2_acc_crd__accounts {
  color: #1e2738;
  font-size: 14px;
}

.txt1_3_acc_crd__accounts {
  color: #1e2738;
  font-size: 12px;
}

.txt2_1_acc_crd__accounts {
  color: rgb(30, 39, 56, 0.9);
  font-size: 20px;
  font-weight: bold;
}

.txt2_2_acc_crd__accounts {
  color: rgb(30, 39, 56, 0.9);
  font-weight: bold;
}

.ac_crds_ttl__accounts {
  font-size: 20px;
  display: flex;
  justify-content: center;
  padding-top: 8px;
}