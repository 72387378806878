.base__search_field{
    box-shadow: none!important;
    background-color: #F3F5F7!important;
    
    display: flex;
    align-items: center;
    border-radius: 10px!important;
    padding: 8px 12px 8px 12px;
}

.input__search_field{
    font-family: 'poppins'!important;
    font-size: 16px!important;
}

.input__search_field .MuiInputBase-input::placeholder {
    color: #1E2738!important;
    opacity: 0.6;
    letter-spacing: 2px!important;
}

.totalDataCount__srchfield{
    padding:12px;
    color: rgb(30,39,56);
    margin-right: -12px;
}