.container__inventory {
  color: black;
  font-family: "Poppins" !important;
  padding-top: 80px;
}

.i-title {
  color: #1e2738;
  font-size: 20px!important;
  font-weight: 600 !important;
}

.i-add-btn {
  font-family: "Poppins" !important;
  background-color: #1e2738 !important;
  color: white !important;
  font-size: 16px !important;

  font-weight: 500 !important;
  border-radius: 8px !important;
  height: 48px;
  width: 280px;
  text-transform: none !important;
  letter-spacing: 1px !important;
}

.i-add-btn-icon {
  font-size: 32px;
  margin-right: 20px;
  color: white !important;
  background-color: #1e2738 !important;
  border-radius: 50%;
  padding: 6px 8px 10px 8px;
  margin-top: -14px !important;
  margin-right: 14px !important;
  cursor: pointer;
}

.i-search {
  border: none !important;
  border-radius: 5px !important;
}

.MuiDialog-paper {
  border-radius: 20px !important;
}

#deleteProductDialogContent__inv {
  height: 430px;
  background: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  letter-spacing: 1px;
}

#deleteProductDialog .MuiDialogActions-root {
  padding: 0 !important;
}

.deleteDialogBtn__inv {
  width: 100% !important;
  font-size: 16px !important;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.deleteDialogBtnYes__inv {
  background-color: #ecae33;
  padding: 20px;
  color: white;
}

.deleteDialogBtnNo__inv {
  background-color: white;
  padding: 20px;
  color: rgba(30, 39, 56, 0.8);
}

.deleteProductDialogActionText1_inv {
  font-size: 20px !important;
  font-weight: 600;
  color: rgba(30, 39, 56, 0.9);
  margin-top: 30px;
}

.deleteProductDialogActionText2_inv {
  font-size: 15px !important;
  color: rgba(30, 39, 56, 0.6);
  margin-top: 30px;
}

.FixedHeightContainer__inv {
  float: right;
  height: 80vh;
  width: 100%;
}
.Content__inv {
  height: 90%;
  width: 100%;
  overflow-y: auto;
}

.wrt__invt {
  font-size: 14px !important;
}

.ttwrt__inv {
  width: 260px;
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  border: 0.5px solid rgba(30, 39, 56, 0.2);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
}


.ttwrt_flt__inv{
  border-radius: 10px!important;
  width: 50px;
  height: 50px;
  background: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  border: 0.5px solid rgba(30, 39, 56, 0.2)!important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08)!important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px!important;
  cursor: pointer!important;
}
.menu_filter__inve{ 
  background-color: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  border: 0.5px solid rgba(30, 39, 56, 0.2)!important;
}
.icon__fil__invet{
  border: 0.5px solid rgba(30, 39, 56, 0.6)!important;
  color: #1e2738;
  height: 47px;
  border-radius: 10px; 
  min-width: 50px!important;  
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer!important;
  

}

.add_order_btn__orders {
  font-family: "Poppins" !important;
  background-color: #1e2738 !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  height: 48px;
  text-transform: none !important;
  letter-spacing: 1px !important;
}

.viewKhata_order_btn__orders {
  font-family: "Poppins" !important;
  background-color: #ECAE33 !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  height: 48px;
  text-transform: none !important;
  letter-spacing: 1px !important;
}

.download_report_btn {
  font-family: "Poppins" !important;
  background-color: white !important;
  color: #1e2738 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  height: 48px;
  text-transform: none !important;
  letter-spacing: 1px !important;
  border: 1px solid #1e2738!important;
  box-shadow: none!important;
}

.download_report_btn__inv {
  font-family: "Poppins" !important;
  background-color: white !important;
  color: #1e2738 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  height: 45px;
  text-transform: none !important;
  letter-spacing: 1px !important;
  border: 1px solid #1e2738!important;
  box-shadow: none!important;
}
