.main_back_view{
  background-color: #ffff;
    height: 100%;
    border-radius: 1px;
    border: 2px solid rgb(0, 0, 0, 0.05);
    position: relative;
    justify-content: center;
    align-items: center;
    }

.first_row{padding: 10px;  height:100%}

.first_row_minus{padding: 15px; }

.head_name_inv{
  margin-top: 15px;
  font-size: 12px;
font-weight: 500;
color: #1E2738;
opacity: 60%;}

.descp_name_inv{
  margin-top: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #1E2738
 }

 .line{  height: 0px;
  border-top: 1px solid rgb(0, 0, 0, 0.08);
       }

  .variant-head{  
    margin-top: 5px;
    font-size: 13px; 
    font-weight: 600;
    color: #1E2738;
   padding-left: 15px;}
  .var_box_back{
    background-color: #ffff;
    height: 120%;
    width:'80%';
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 12px;
    border-radius: 1px;
    border: 1px solid rgb(0, 0, 0, 0.07);
    position: relative;
    justify-content: center;
    align-items: center;
  }
    .var_box_row1{
      margin-top: 5px;

      padding-left: 2px;
      padding-right: 2px;    }

    .var_box_head{
      margin-top: 5px;
      font-size: 12px;
      margin-bottom: 5px;
      text-align:left;
      font-weight: 600;
      color: #1E2738;
  
  }

  .var_inp{
    font-size: 10px;
    background-color: #F3F5F7;
    width: 90%;
    margin-bottom:10px;
    height:30px;
    border-radius: 5px !important;}
    .var_inp_quan{
      font-size: 13px;
      background-color: #F3F5F7;
      width: 80%;
      height:30px;

      border-radius: 5px !important;}
    .var_inp_spec{
      font-size: 11px;
      background-color: #F3F5F7;
      width: 90%;
     height:auto;padding: 2px;
      border-radius: 5px;
      border: 1px solid rgb(0, 0, 0, 0.25);
      }
      .var_inp_imei
      {
        font-size: 11px;
        background-color: #F3F5F7;
        width: 95%;
        padding: 2px;
        padding-right: 2px;  
        height:auto;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.25);
        }

      .var_inp_clr{
        font-size: 13px;
        background-color: #F3F5F7;
        width: 80%;
       height: 28px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.25);
        }
        

      .bnkmnu_field {
        background-color: #F3F5F7 !important;
        color: #1a2937 !important;
        border-radius: 5px !important;
        font-size: 15px !important;
        box-shadow: none !important;
        width: 100% !important;
        text-align: start !important;
        height: 30px !important;
        text-transform: none !important;
        border: 1px solid rgb(0, 0, 0, 0.25) !important;
        display: flex !important;
       
    }
    .scroll_var {
      overflow-x: hidden;
      overflow-y: scroll;
      white-space: nowrap;
    }