.textContainer {
  padding: 20px;
  margin-top: 20vh;
  z-index: 10;
}

.firstText {
  font-size: 24px;
}

.secondText {
  font-size: 62px;
}

.thirdText {
  font-size: 24px;
  color: #ECAE33;
}

.textCenter {
  text-align: center;
  color: white;
}
