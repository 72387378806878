.chip_cont__adrepser{
    flex-flow: row wrap;
}
/* .tokenChip {
    background-color: pink;
    width: 100px;
  }
  .MuiChip-clickable:hover {
      background-color: yellow;
  }
  .MuiChip-clickableColorPrimary:hover {
    background-color: black;
  } */
  .servise__plus_icon{
    width: 37px;
    height: 37px;
    background-color: #ccc;
    border-radius: 20px;
    border: 1px solid rgb(194, 201, 209);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(34, 31, 31);
    background-color: rgb(139, 171, 182);
     }