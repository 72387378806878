.chat__field {
  border: 1px solid black !important;
  background-color: white !important;
}
.chat_img__box {
  height: 30px;
  width: 30px;
  border-radius: 10px;
  background-color: rgb(128, 103, 103) !important;
}
.text_chat_sm {
  color: "#1E2738";
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.chat__heading {
  font-size: 15px;
}
.chat__time {
  font-size: 10px;
}
.chatItem:hover {
  background-color: #991e2738 !important;
  color: white !important;
}

.chatItem:hover {
  color: white !important;
}
.chat_box__convers {
  padding: 5px;
  max-width: 460px;
  background-color: white;
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.chat_box_two__convers {
  padding: 5px;
  max-width: 460px;
  background-color: #6d6d6d !important;
  border-bottom-left-radius: 17px;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: white;
}
.chat_now__txt {
  font-size: 10px;
  color: gray;
  padding-top: 6px;
}
.chat_days {
  font-size: 10px;
  color: #6d6d6d;
  padding-top: 4px;
}

.message_con__wapchat {
  background-color: #e7e7e7;
  height: 100vh;
}
.main_drawer__chat {
  background-color: #f4f4f5 !important;
  height: 100% !important;
}

.empty_chat_screen__chat {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.text1__chat {
  text-align: center;
  font-size: 28px;
  color: #09132c;
}

.text2__chat {
  text-align: center;
  font-size: 22px;
  color: rgb(30, 39, 56, 0.7);
}

.display_center__chat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message_con__chat {
  background-color: red;
  height: 100%;
  width: 100%;
}

.chat_notif_con__chat {
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
  background-color: #21B3F5;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-top: 5px;
}
