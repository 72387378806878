/* LandingPage.css */

.landing_point {
  list-style: none !important;
  display: flex !important;
}

/* FeaturePoints.css */
.landing_paragh_text {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 140%;
  color: white;
  padding-top: 10px;
}
.header_btn_engls__lndpg {
  border: none !important;
  color: rgba(137, 141, 150, 1) !important;
  text-transform: none !important;
  width: 118px !important;
  display: inline-flex !important;
}
.landing_page_btn {
  background-color: #1e2738 !important;
  color: white !important;
  border-radius: 12px !important;
  text-transform: none !important;
}
.landing_manage_text {
  font-weight: 700 !important;
  color: white !important;
}
.md_down_sign_btn {
  display: flex;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center !important;
  color: #1e2738 !important;
  text-align: center;
  font-size: 16px;
  background-color: rgb(245, 245, 245) !important;
  border-radius: 30px !important;
}

.flex-items {
  margin: 10px;
  font-size: 18px;
  color: #898d96;
}
.flex2 {
  justify-content: space-around;
  display: flex !important;
  cursor: pointer !important;
}

.landing_features {
  height: 100px !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
}
.landing_btn {
  box-sizing: border-box;
  height: 46px;
  width: 130px;
  font-family: "Poppins";
  font-size: 16px !important;
  background-color: #eeeff0 !important;
  color: #1e2738 !important;
  border: 0.4px solid rgba(137, 141, 150, 1) !important;
  border-radius: 10px !important;
  text-transform: none!important;
}
.landing_btn:hover{
  background-color: whitesmoke!important;
}
.feature_point {
  color: #898d96 !important;
  text-transform: none !important;
  letter-spacing: 0.3ch !important;
}
.db {
  height: 520px;
  width:100%;
  background: linear-gradient(135deg, #ffcf71 0%, #2376dd 100%);
  border-radius: 48px 48px 170px 48px;
  padding-bottom: 20px;
  position: relative;
}
@media (max-width: 675px) {
  .image {
    max-width: 100%;
  }
}
/* LandingHeader.css */

.header_landing_main {
  height: 80px;
  width: 100%;
  background-color: #1e2738 !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
}

.header_btn_con__lndpg {
  display: flex;
  width: 100%;
}

.header_btn1__lndpg {
  border: none !important;
  color: white !important;
}

/* DetailBox.css */

.image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;

  padding: 20px;
}

.image {
  width: 93%;
  max-width: 675px;
  border-radius: 20px !important;
}

@media (max-width: 768px) {
  .imageland {
    max-width: 90%;
    height: 50%;
  }
}
@media (max-width: 1024px) {
  .imageland {
    max-width: 110%;
    height: 50%;
  }
}
@media (max-width: 260px) {
  .image {
    max-width: 260px;
    padding: 30px;
  }
}

@media (max-width: 260px) {
  .image {
    width: 400%;
    padding: 26px;
  }
}
---------- text .text-container {
  display: flex;
  justify-content: center;
}

.text {
  font-size: 29px;
}

.description {
  font-size: 16px;
  max-width: 500px;
}

@media (max-width: 975px) {
  .text {
    font-size: 21px;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .text {
    font-size: 20px;
  }

  .description {
    font-size: 12px;
  }
}
.maim_box {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}
.detailText_md_down_small_tx {
  display: flex;
  text-align: center;
  padding: 10px;
}
.detailText_md_down {
  display: flex;
  justify-content: center;
  margin: 0;
}
.Img_box {
  border-radius: 20px;
}
.text_box {
  width: 575px !important;
  height: 450px;
  margin-left: 30px;
}
.img_border_lp {
  border-radius: 20px !important;
  max-width: 375px;
  height: auto;
  /* width: 400%; */
}
.title_text_lp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
  color: #1e2738;
}
.txt_lp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #1e2738;
  padding-top: 14px;
}

/* LandingFooter.css */

.lp_footer_main {
  width: 100%;
  color: white !important;
  background-color: #1e2738 !important;
}

.main_foot_title {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  padding-top: 20px;
  cursor: pointer;
}
.text_foot_two {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding: 27px;
  cursor: pointer;
}

/* DetailBoxFooter.css */

.detail_footer {
  width: 100%;
  background-color: #1e2738 !important;
  color: white !important;
}
.detail_footer_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.detail_footer_btn {
  height: 55px;
  width: 200px;
  color: white !important;
  background: #ecae33 !important;
  border-radius: 10px !important;
}
.dbl_color {
  color: #ecae33 !important;
}

/* MainFooter.css */

.landing_Footer_icons {
  display: flex;
  align-items: center;
  color: white;
  padding-right: 30px;
}

/* Features.css */
.landing_features {
  width: 100%;
  height: 100vh;
}

/* Services.css */

.service_cards {
  width: 310px !important;
  background: rgba(255, 255, 255, 0.25) !important;
  box-shadow: 0px 10px 50px rgba(236, 174, 51, 0.15) !important;
  border-radius: 48px !important;
}
.service_inventory {
  padding-bottom: 40px !important;
  padding-top: 20px;
  width: 168px;
  height: 68px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #1e2738;
}
.flex_branches {
  display: flex;
  flex-flow: row wrap !important;
  justify-content: center;
}

/* Overview.css */
.overview_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 68px;
  color: #1e2738;
}
.overview_account {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1e2738;
  padding-top: 10px;
}
.brand_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(30, 39, 56, 0.75);
  padding-top: 15px;
}
.overview_btn {
  height: 45px;
  width: 175px;
  color: white !important;
  background: #1e2738 !important;
  border-radius: 10px !important;
  margin-top: 30px !important;
}

/* Benefit.css */

.benefit_txt {
  width: 471px;
  height: 150px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 1000;
  font-size: 50px;
  line-height: 75px;
  color: #1f2738;
  cursor: pointer;
}
.benefit_points {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #1f2738;
  display: flex !important;
  align-items: center !important;
  padding-top: 40px;
}
.benefit_sc_main {
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

/* LastFooter.css */

.lst_footer_main {
  width: 100%;
  background-color: #1e2738;
  color: white;
}
.parkExpre_txt {
  width: 392px;
  height: 30px;
  cursor: pointer !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  color: #e4a02c;
}
.cr_last_lp {
  cursor: pointer;
}
.produt_txt {
  width: 285px;
  height: 30px;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  color: rgba(255, 255, 255, 0.6);
}
.lstField_d {
  flex: 1 !important;
  padding-left: 10px !important;
  color: white !important;
  height: 55px !important;
  width: 350px !important;
  border: 2px solid rgba(255, 255, 255, 0.6) !important;
  border-radius: 30px !important;
  /* background-color: #1f2738!important; */
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e2738;
  color: white;
}

.footer-left {
  display: flex;
  flex-direction: column;
  padding-top: 40px !important;
  padding-left: 120px !important;
  padding-bottom: 20px !important;
}

.footer-left p {
  margin: 5px;
}

.footer-right {
  display: flex;
}

.column {
  margin: 15px;
  padding: 20px;
}

.column h3 {
  margin-bottom: 10px;
}

.column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.column li {
  margin: 5px 0;
}
.list_desgn {
  /* Help centre */
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
}
.points_main_heading {
  /* Support */
  width: 73px;
  height: 27px;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #ffffff;
}
