.side_menu {
  background-color: #1e2738;
  color: white;
  height: 100vh;
  width: 280px;
  font-family: "Poppins";
  position: fixed !important;
  z-index: 6;
}

.container__sidmn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.header_sidmn {
  flex-shrink: 0;
}
.body__sidmn {
  flex-grow: 1;
  overflow: auto;
  min-height: 2em;
  margin-top: -10px;
}

.body__sidmn::-webkit-scrollbar {
  width: 0px !important;
}

.body__sidmn::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 0px !important;
}

.body__sidmn::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.footer__sidmn {
  flex-shrink: 0;
  margin-bottom: 20px;
  margin-top: 30px;
}

.sideMenuBtn {
  font-size: 28px;
  margin-right: 10px;
  cursor: pointer;
}
.sideMenuBtn:hover{
  color: rgb(211, 196, 196)!important;
}

.nav_links {
  text-decoration: none !important;
  color: white;
  white-space: nowrap;
}

.logo__side_menu {
  /* background-image: url("../../public/images/logo.png");
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100px 100px; */
  margin-top: -20px !important;
}

.icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  align-self: center;
}

.drop_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  margin-right: 50px;
  align-self: center;
}
.menuButton {
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  margin: 0px 0 5px 0;
  display: flex;
  align-items: center;
  width: 100% !important;
  text-transform: none !important;
  font-size: 16px !important;
}

/* .menuButton:hover {
  background-color: rgb(236, 174, 51, 0.3) !important;
  cursor: pointer;
} */

.logout-btn {
  border: white 1px solid;
  border-radius: 5px;
  padding: 8px 12px 8px 12px;
}

.logout-btn:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.2);
}
.subMenu-item {
  text-decoration: none !important;
  color: white;
  white-space: nowrap;
  font-size: 13px;
}

.subMenu__sidemnu {
  border: 1px solid #ecae33;
  border-radius: 5px;
  margin: 0px 10px 0px 10px;
}

.menuSubArw__sidemnu {
  font-size: 24px;
  display: flex;
}

.menuSubCon__sidemnu {
  display: flex;
  justify-content: space-between;
}

.subMenuStl__sidmnu {
  width: 260px;
  margin: 0px 0 0px 0;
}

.sidarw_rt {
  transform: rotate(180deg);
}

.subMenu_highlighted__sidemnu {
  background-color: rgb(236, 174, 51, 0.3);
  padding: 10px 0;
}

.subMenu_highlighted2__sidemnu {
  background-color: rgb(236, 174, 51, 0.3);
}

.sideSubMenu_padding__sidmnu {
 padding-left: 16px;
 padding-top: 2.5px;
 padding-bottom: 0px;
 padding-right: 0;
}

.sideSubMenu_hover__sidmnu:hover{
  background-color: rgb(255, 255, 255, 0.2)!important;
}

.text1__sidemnu{
  font-size: 15px!important;
  display: flex;
  align-items: center!important;
}

.icons1__sidemnu{
  font-size: 14px!important;
  display: flex;
  align-items: center;
}
