.con__mltm {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.list__mltm {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.list_item__mltm {
  padding-top: 8px !important;
  padding-bottom: 0 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  cursor: pointer;
}

.list_item__mltm:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.btb_item__mltm {
  width: 99%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1) !important;
}

.item_text__mltm {
  color: black;
}

.base_sf__mltm {
  box-shadow: none !important;
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  padding: 12px 12px 10px 12px;
  border: none !important;
  width: 100%;
}

.input_sf__mltm {
  font-family: "poppins" !important;
  font-size: 16px !important;
}

.input_sf__mltm .MuiInputBase-input::placeholder {
  color: black !important;
  opacity: 0.4;
}

.list_data__mltm {
  overflow: auto;
}

.list_data__mltm::-webkit-scrollbar {
  display: none;
}

.prontf__mltm {
  font-weight: 100;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding-top: 10px;
}
