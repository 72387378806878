.MuiPaper-root {
  border-radius: 10px !important;
  box-shadow: none !important;
}
.phone_input_search{
  width: 100%!important;
  height: 55px!important;
  border-radius: 10px!important;
}
.button_input_field{
  border-radius: 10px 0px 0px 10px!important;
  min-width: 46px!important;
}

.add_product_btn__adord {
  background-color: rgb(30, 39, 56, 0.3) !important;
  border-radius: 10px !important;
  text-transform: none !important;
  font-size: 14px !important;
  margin-top: 12px !important;
  color: rgb(30, 39, 56, 1) !important;
  font-weight: 600 !important;
}

.product_inv__addord {
    color: black!important;
}

.price__addord{
  color: black!important;
  font-weight: 500!important;
}

.prod_added_des__addord{
  border: 1px solid rgb(0, 0, 0,0.2)!important;
  border-radius: 10px;
  background-color: white;
  font-weight: lighter;
  color: black;
  padding: 5px;
}
