.box_img__vendor {
  height: 80px !important;
  width: 80px !important;
  border-radius: 20px !important;
  background-color: white !important;
}
.Box_background__vendor {
  border-radius: 20px !important;
  background-color: #edebfe;
  padding: 10px !important;
}
.padn_invoice__icon{
  padding-right: 5px!important;
}
.field_text_main_global {
  margin-bottom: 8px !important;
}
.add_vendor_back_icon {
  margin-bottom: -5px !important;
  font-size: 20px !important;
}
.total_balnac_top {
  margin-top: 5px !important;
}
.box_img__vendor {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.Balance_vendors__khata {
  font-size: 20px;
  font-family: "Poppins";
  color: #1a2937;
}
.icons_w_h_vendors {
  width: 48px !important;
  height: 48px !important;
}
.AED_vendors__khata {
  font-size: 26px;
  font-family: "Poppins";
  font-weight: bold;
}
.header__ai__vendor_khata {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 250, 255, 1) 38%
  );

  height: 75px;
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.boxes__vendor___khata {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.add_Button_back {
  background-color: white;
  height: 45px;
  width: 45px;
  border-radius: 22.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_add_btn {
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.trasns_form_heading {
  align-items: center;
  justify-content: center;
  display: flex;
}
.head_text {
  align-self: center;
  color: "#1E2738";
  font-weight: bold;
  font-size: large;
}
.title__addTransaction {
  color: "#1E2738";
  font-size: small;
  font-weight: bold;
}
.textField__addTransaction {
  background-color: white;
  width: 80%;
  font-size: medium;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-top: "-1px";
}
.card__ai {
  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 250, 255, 1) 38%
  );
}
