.boxes__notes {
  width: 250px !important;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.notes_icon__styl {
  font-size: 70px !important;
  color: #cfcbcb !important;
}
.boxes_od__notes {
  width: 100% !important;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.shoh__n_notes {
  font-weight: bold;
}
.notes__text_i {
  font-size: 19px !important;
  margin-top: 8px !important;
  font-family: "Poppins";
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}
.notes__icon_sz {
  font-size: 28px !important;
}

/* .tex_notes__sx {
  font-size: 15px;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.notesCardDialogScroll::-webkit-scrollbar {
  width: 12px !important;
}

.notesCardDialogScroll::-webkit-scrollbar-thumb {
  background: #ecae33 !important;
  border-radius: 6px;
}

.notesCardDialogScroll::-webkit-scrollbar-track {
  background-color: #1e2738 !important;
}

.notesCardDialog .MuiDialogContent-root {
  padding: 0 !important;
}

.tex_notes_od__sx {
  font-size: 15px;
  margin-top: 20px;
}

.tex_notes__sx {
  font-size: 15px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 90px;
  text-align: justify;
  padding-right: 1em;
}

.tex_notes__sx:before {
  content: "…";
  position: absolute;
  right: 0;
  bottom: 0;
}

.tex_notes__sx:after {
  content: "";
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
}

.text__filed__notes {
  width: 100% !important;
}

.notes_txt_m {
  font-size: 26px;
  margin-right: 10px !important;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
}

.testing__notes_col {
  background-color: #ccc;
  border-radius: 7px;
}
