.product_title_text__ordview {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 100 !important;
    margin-bottom: 5px;
    letter-spacing: 1px !important;
  }
  
  .product_text__ordview {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.8) !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
  }
  
  .order_ti__ordview {
    display: flex;
    flex-wrap: wrap;
  }
  
  .viwKhataBtn__ordview {
    background-color: #ecae33 !important;
    border-radius: 10px !important;
    text-transform: none !important;
  }
  
  .bg__ordview {
    background: linear-gradient(
      290.97deg,
      rgba(212, 231, 254, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border: 0.25px solid rgba(30, 39, 56, 0.2);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 0px 10px 10px 10px !important;
  }
  
  .variant_box_con__ordview {
    background-color: white;
    border: 0.25px solid rgba(30, 39, 56, 0.2);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
  }
  