.i-title_tranfer_order {
    color: #1e2738!important;
    font-size: 20px;
    font-weight: 500 !important;
  }
  .transf_btn{
  background-color: #1e2738!important;
  color: white!important;
  height:44px!important;
  width: 100px!important;
  text-transform: none!important;
  border-radius: 10px!important;
  margin-left: 5px!important;
  }
  