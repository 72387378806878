.uinfo_con__user {
  background: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  position: relative !important;
}

.uinfo_close_btn_con__user {
  position: absolute !important;
  right: 1px;
  top: 1px;
}

.uinfo_close_btn__user {
  color: rgba(255, 0, 0, 0.8);
}
.mobile_stipper_back {
  background-color: transparent !important;
}
.uinfo_dtl_con__user {
  width: 100%;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
}
.user_icon_sty {
  font-size: 20px !important;
  margin-top: -2px !important;
}

#deleteProductDialogContent__user {
  height: 260px;
  background: linear-gradient(304.92deg, #d4e7fe -45.35%, #ffffff 76.43%);
  letter-spacing: 1px;
}
