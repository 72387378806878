.confirm-logout-btn{
  background-color: #173D52!important;
}

.confirm-logout-btn-hover:hover {
  background-color: #173D52!important;
  border-color: #173D52!important;
}

.branch_wel_txt {
  color: white;

  font-weight: bold;
  padding-bottom: 7px;
}
.branch_selec_txt {
  color: white;
  letter-spacing: 0.8;
}
.braches_card_box_main {
  height: 280px;
  width: 270px;
  background-color: white;
  border-radius: 11px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.branches_card__box_two {
  height: 135px;
  width: 270px;
  background-color: #96ddb9;
  border-radius: 11px;
}
.branche_txt_name {
  font-size: 17px;
  color: #1e2738;
  font-weight: bold;
}
.branch_image_fit_con {
  width: 270px !important;
  height: 135px !important;
  object-fit: cover !important;
}
.branch_above_lenght_image_fit_con {
  width: 90px !important;
  height: 73px !important;
  object-fit: cover !important;
}
.branche_txt_ALCC {
  font-size: 14px;
  color: #1e2738;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.edit_branches_btn {
  height: 44px;
  font-family: "Poppins" !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 119px;
  background-color: #1e2738 !important;
  color: white !important;
  text-transform: none !important;
  letter-spacing: 1px !important;
  border: inherit;
}
.delete_branches_btn {
  height: 44px;
  font-family: "Poppins" !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 119px;
  background-color: #e7ebed !important;
  color: #1e2738 !important;
  text-transform: none !important;
  letter-spacing: 1px !important;
  border: inherit;
}
/* .branche__box_M {
  height: 150vh !important;
  width: 100% !important;
  background-color: #f6f8f9;
} */
.flex_branches {
  display: flex;
  flex-flow: row wrap !important;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
}
.sm_Box_branches_icon {
  height: 48px;
  width: 48px;
  background-color: white;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sm_Box_branches_icon:hover{
  background-color: rgb(219, 209, 209)!important;
}

/* New Branches Screen css */

.login_pin_code_popup__branch {
  width: 400px;
  background-color: white !important;
  border-radius: 10px;
}

.main_new_branche {
  width: 700px;
  background-color: white !important;
  border-radius: 10px;
}

.text1__branch {
  font-size: 16px;
  color: #1e2738;
}

.create_n_branche_txt {
  color: #1e2738;
  font-size: 19px;
  font-weight: 600;
}

.loader_pincode__branch {
  width: 100vw;
  height: 100vh;
  background-color: #1e2738;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branche_btn_main {
  height: 48px;
  width: 100%;
  background-color: #0f354c !important;
  color: white !important;
  font-size: 14px;
  border-radius: 11px !important;
  margin-top: 24px !important;
  text-transform: none;
  margin-right: 5px !important;
}

/* BranchSetting css */

.bran__setting_main {
  height: 500vh;
  width: 800px;
}
.branch_setting_secound {
  height: 500px;
  width: 600px;
  background-color: white !important;
}
/* .side_setting_branch{
  height: 500px;
  width: 200px;
  background-color: rgb(224, 217, 218)!important;
} */
.bran_setting_txt_name {
  color: #0f354c !important;
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 500;
  text-align: center;
}
.bran_setting_txt_Alc {
  color: #0f354c !important;
  font-size: 14px !important;
  font-family: "Poppins" !important;
  text-align: center !important;
}
.setting_branc_btn {
  height: 40px;
  width: 200px;
  border: inherit !important;
  letter-spacing: 0.9px !important;
  font-size: 13px !important;
  text-transform: none !important;
}
.setting_branc_btn_one {
  height: 40px;
  width: 200px;
  background-color: #0f354c !important;
  color: white !important;
  border: inherit !important;
  letter-spacing: 0.9px !important;
  font-size: 13px !important;
  text-transform: none !important;
}
.branche_seting_log {
  height: 40px;
  width: 200px;
  color: #0f354c !important;
  font-size: 14px !important;
  border-bottom: 1px solid #c4c4c4 !important;
  letter-spacing: 0;
  text-transform: none !important;
  border-top: 1px solid #c4c4c4 !important;
  font-weight: 600 !important;
}
.branch_st_genral {
  font-size: 19px;
  color: #0f354c;
  font-weight: 600;
  font-family: "Poppins";
  letter-spacing: 0.9;
}
.update_brach_set {
  font-size: 16px;
  font-family: "Poppins";
  color: #1e2738;
}
.up_brn_st_btn_one {
  height: 43px !important;
  width: 150px !important;
  background-color: #1e2738 !important;
  border-radius: 10px !important;
  color: white !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.up_brn_st_btn_two {
  margin-left: 15px !important;
  height: 43px !important;
  width: 150px !important;
  background-color: #e4e6e7 !important;
  border-radius: 10px !important;
  color: #1e2738 !important;
  text-transform: none !important;
  font-size: 16px !important;
}
.box_close_icon {
  height: 37px;
  width: 37px;
  background-color: #1e2738;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.ft_sixe {
  color: white !important;
}
.box_close_icon:hover {
  background-color: #13405c !important;
}
.main_img_branch {
  width: 200px;
  background-color: white;
  border-right: 1px solid #c5c5c5;
  border-radius: 10px;
}

.login_branch__branch {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.background_image__branch {
  background-image: url("../../assets/Images/BranchMainImg.jpg");
  width: 100%;
}

.branche_btn_cancel_main {
  height: 48px;
  width: 100%;
  background-color: white !important;
  color: #0f354c !important;
  font-size: 14px;
  border-radius: 11px !important;
  margin-top: 24px !important;
  text-transform: none;
  border: 1px solid #13405c !important;
  margin-left: 5px !important;
}

/* Branch Settings */

.text1_branchSettings {
  font-size: 14px;
  color: black;
  opacity: 0.8;
}

.main_con_branchSettings {
  display: flex;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
  border-radius: 10px;
}
