.box_img__vendor_pr {
    height: 50px !important;
    width: 50px !important;
    border-radius: 5px !important;
    background-color: white !important;
    
  } 
  .Box_background__vendor_pr{
    border-radius: 20px!important;
    /* background-color: #EDEBFE; */
  }
  .partner_padng_row{
    padding-right: 5px!important;
  }
  .icon_balanc_main{
    background-color:#EDEBFE!important;
    margin-bottom: 6px!important;
  }
  .icon_col_partner{
  color:#1E2738!important;
  }
  .Balance_vendors__khata_pr{
    font-size: 12px;
    font-family: 'Poppins'; 
    color: #1A2937;
  }
  .AED_vendors__khata_pr{
    font-size: 16px;
    font-family: 'Poppins'; 
    font-weight: bold;
  }
  .header__ai__vendor_khata_pr {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 250, 255, 1) 38%
    ); 
  
    height: 60px;
    border-radius: 8px;
    border: 1px solid rgb(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
.boxes__vendor___khata_pr{
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.add_Button_back_pr{
background-color: white;
height: 25px;
width: 25px;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
}

.container_add_btn_pr
{align-items: center;
  justify-content: center;
  z-index: 1;}

  .trasns_form_heading_pr{
    align-items: center;
    justify-content: center;
    display: flex;
 }
.head_text_pr{  align-self: center;
  color:'#1E2738';
  font-weight: bold;
font-size: large;}
.title__addTransaction_pr
    {
    color:'#1E2738';
    font-size: small;
   font-weight: bold;}
.textField__addTransaction_pr {
  background-color: white;
  width: 80%;
  font-size: medium;
  border-color: rgb(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-top: '-1px';

}
.card__ai_pr {

  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 250, 255, 1) 38%
  );
}
