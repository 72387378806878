.mobile_menu{
  background-color: #1e2738;
  color: white;
  height: 600px;
  width: 100vw;
  font-family: "Poppins";
  position: fixed !important;
  z-index: 5;
}

.nav_links__mtm {
  text-decoration: none !important;
  color: white;
  white-space: nowrap;
}


.icon__mtm {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  align-self: center;
}

.menuButton__mtm {
  width: 260px;
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  margin: 5px 0 5px 0;
}

.menuButton__mtm:hover {
  background-color: rgb(236, 174, 51, 0.3);
  cursor: pointer;
}

.menuBtnContainer__mtm{
  display: flex;
  justify-content: center;
  width: 100vw;
}

.logo__mtm {
  background-image: url("../../public/images/logo.png");
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: 120px 120px;
}

.menuBtn__mtm{
  font-size: 28px;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
}

.logout-container__mtm {
  display: flex;
}

.mobile-logout-btn__mtm {
  border: white 1px solid;
  border-radius: 5px;
  width: 120px;
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  margin: 5px 0 5px 0;
}

.logout-btn__mtm:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.2);
}